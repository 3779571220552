<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    stroke="url(#paint0_linear_14935_64486)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.75 19.375L15 10.625L6.25 19.375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient
        id="paint0_linear_14935_64486"
        x1="23.75"
        y1="14.9446"
        x2="6.25"
        y2="14.9446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
